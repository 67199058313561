import { faHashtag, faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import i18n from "../../../../../models/i18n";
import { format } from "date-fns";

function sanitizePurchases(purchases) {
  return purchases.reduce((acc, purchase) => {
    const duplicate = acc.find(
      (sp) => sp.purchase.user?.username === purchase.user?.username
    );
    let quantity = 1;

    if (duplicate) {
      quantity += duplicate.quantity;
      acc = acc.filter((sp) => sp !== duplicate);
    }

    acc.push({ quantity, purchase });

    return acc;
  }, []);
}

export function PurchaseHistory({ user, wish, wishlist }) {
  if (!wish.purchases || wish.purchases.length === 0) {
    return <></>;
  }

  const sanitizedPurchases = sanitizePurchases(wish.purchases);

  return (
    <div
      className="post-meta-info purchase-history"
      style={{ flexWrap: "wrap" }}
    >
      {sanitizedPurchases.map(({ purchase, quantity }, index) => (
        <PurchaseHistoryItem
          key={purchase.createdAt}
          purchase={purchase}
          user={user}
          wishlist={wishlist}
          quantity={quantity}
          className={index % 2 === 0 ? "meta-left" : "meta-right"}
        />
      ))}
      {sanitizedPurchases.length % 2 !== 0 && (
        <div className="meta-right" style={{ flexShrink: 0, flexBasis: "50%" }}>
          <span></span>
        </div>
      )}
    </div>
  );
}

export function PurchaseHistoryItem({
  purchase,
  user,
  wishlist,
  quantity,
  className,
}) {
  let translationKey;
  const isOwner = wishlist.owner && (wishlist.owner.username === user?.username);
  const forOwner = isOwner || wishlist.isCollaboration;
  const date = format(new Date(purchase.createdAt), "dd.MM.yyyy");

  if (!forOwner) {
    translationKey = "wish.boughtOn";
  } else if (purchase.user) {
    translationKey = "wish.boughtBy";
  } else {
    translationKey = "wish.boughtByAnonymous";
  }

  return (
    <div className={className}>
      <FontAwesomeIcon
        icon={faShoppingBasket}
        className="icon"
        style={{ marginRight: "4px", marginTop: "2px" }}
      />

      <span>
        {i18n.t(translationKey, { name: purchase.user?.username, date })}
        {(quantity || 1) > 1 ? ` (${quantity} x)` : ""}
      </span>
    </div>
  );
}
