import * as Price from "@bitte-kaufen/bitte-kaufen-common/dist/src/models/price";
import classNames from "classnames";
import { withI18n } from "../../../hooks/use-i18n";
import { updateWish } from "../../../models/wish";
import "../style.less";
import WishActions from "./WishActions";
import { decorateWish } from "@bitte-kaufen/bitte-kaufen-common/dist/src/decorators/wish-decorator";

class WishEditor extends React.Component {
  constructor(props) {
    super(props);

    const { i18n } = props;

    this.state = Object.assign({}, props);
    this.state.transientWish.price = Price.format(
      this.state.transientWish.cents || 0,
      this.state.transientWish.currency || "EUR",
      i18n.currentLocale()
    ).money;
  }

  async changeWish(e, data) {
    e.preventDefault;

    await updateWish(this.state.wishlist.uuid, this.state.uuid, data);

    this.setState(data);
  }

  async reserve(e) {
    await this.changeWish(e, { reservedAt: new Date() });
  }

  async unreserve(e) {
    await this.changeWish(e, { reservedAt: null });
  }

  updateWish(e) {
    e.preventDefault && e.preventDefault();
    const inputField = e.target;

    this.setState({
      transientWish: {
        ...this.state.transientWish,
        [inputField.name]: inputField.value,
      },
    });
  }

  persistChanges(e) {
    this.state.transientWish.cents =
      (Price.parse(this.state.transientWish.price) || 0) * 100;
    this.state.transientWish.currency = Price.extractCurrency(
      this.state.transientWish.price
    ).currency;

    delete this.state.transientWish.price;

    this.props.persistWishUpdate(e, this.state.transientWish);
  }

  handleImageUpdate(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener("load", (event) => {
      this.updateWish({
        target: { name: "image", value: event.target.result },
      });
    });
    reader.readAsDataURL(file);
  }

  render() {
    const wishImage = this.state.transientWish.image || this.props.image;
    const { i18n } = this.props;
    const decoratedWish = decorateWish(this.state);

    return (
      <>
        <div className="post-img">
          <div
            className="image-wrapper"
            style={{ backgroundImage: `url(${wishImage})` }}
          />
          <input
            type="file"
            name="image"
            accept="image/png, image/jpeg"
            onChange={(e) => this.handleImageUpdate(e)}
          />
        </div>
        <div className="list-content wish-editor">
          <form onSubmit={(e) => this.persistChanges(e)}>
            <div className="post-header ">
              <label>
                {i18n.t("web.wishlistsShow.edit.title")}
                <input
                  type="text"
                  name="title"
                  value={this.state.transientWish.title}
                  onChange={(e) => this.updateWish(e)}
                />
              </label>
            </div>

            <div className="post-header ">
              <label>
                URL
                <input
                  type="text"
                  name="url"
                  value={this.state.transientWish.url}
                  onChange={(e) => this.updateWish(e)}
                />
              </label>
            </div>

            <div className="post-header ">
              <label>
                {i18n.t("editor.wishlist.price")}
                <input
                  type="text"
                  name="price"
                  value={this.state.transientWish.price}
                  onChange={(e) => this.updateWish(e)}
                />
              </label>
            </div>

            <div className="post-header">
              <label>
                {i18n.t("editor.wish.quantity")}
                <input
                  type="text"
                  name="quantity"
                  value={this.state.transientWish.quantity}
                  onChange={(e) => this.updateWish(e)}
                />
              </label>
            </div>

            <div className={classNames("post-entry", "is-excerpt")}>
              <label>
                {i18n.t("editor.wishlist.description")}
                <textarea
                  name="description"
                  rows="5"
                  value={this.state.transientWish.description}
                  onChange={(e) => this.updateWish(e)}
                />
              </label>

              {this.props.forOwner || (
                <div className="wish-actions">
                  {!decoratedWish.completed && (
                    <a
                      className="read-more action"
                      onClick={(e) => this.reserve(e)}
                    >
                      {i18n.t("wishlist.willBuy")}
                    </a>
                  )}
                  {decoratedWish.completed && (
                    <a
                      className="read-more action"
                      onClick={(e) => this.unreserve(e)}
                    >
                      {i18n.t("wishlist.nevermind")}
                    </a>
                  )}
                </div>
              )}
            </div>

            <div className="post-meta">
              <WishActions
                {...this.props}
                onDone={(e) => this.persistChanges(e)}
              />
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default withI18n(WishEditor);
