import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useI18n } from "../../../../hooks/use-i18n";
import "./style.less";

const MySwal = withReactContent(Swal);

export default async function triggerGiftFinderNoWishlistsYet(callback) {
  setTimeout(() => {
    party.sparkles(document.querySelector(".swal2-popup"));
  }, 100);

  await MySwal.fire({
    html: <NoWishlistYetBody />,
    icon: "warning",
    timer: 3000,
    timerProgressBar: true,
  });

  callback();
}

function NoWishlistYetBody() {
  const i18n = useI18n();

  return (
    <div className="no-wishlist-yet-body">
      {i18n.t("giftFinder.noWishlistYetBody")}
    </div>
  );
}
