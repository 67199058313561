import fallbackImage1 from "../wunsch-standard-1-bitte-kaufen.jpg";
import fallbackImage2 from "../wunsch-standard-2-bitte-kaufen.jpg";
import fallbackImage3 from "../wunsch-standard-3-bitte-kaufen.jpg";
import fallbackImage4 from "../wunsch-standard-4-bitte-kaufen.jpg";
import fallbackImage5 from "../wunsch-standard-5-bitte-kaufen.jpg";

const fallbackImages = [
  fallbackImage1,
  fallbackImage2,
  fallbackImage3,
  fallbackImage4,
  fallbackImage5
];

export function getFallbackImage(date) {
  return fallbackImages[new Date(date) % fallbackImages.length];
}
