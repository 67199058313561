import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import "./style.less";
import classNames from "classnames";
import { withMarkdownLinks } from "@bitte-kaufen/bitte-kaufen-common/dist/src/models/string";

export default function WishlistHeader(props) {
  const [collapse, setCollapse] = useState(true);
  const featItemRef = useRef();
  const featOverlayRef = useRef();

  useEffect(() => {
    const featItem = featItemRef.current;
    const featOverlay = featOverlayRef.current;

    if (featItem && featOverlay) {
      setTimeout(() => {
        featItem.style.setProperty(
          "height",
          `${featOverlay.offsetHeight + 50}px`,
          "important"
        );
      }, 1);
    }
  }, [collapse]);

  return (
    <div id="featured-area" className="promo-active wishlist-header">
      <div className="sideslides">
        <div className="bxslider">
          <div
            className={classNames(["slide-item", { uncollapse: !collapse }])}
          >
            <div
              className="feat-item"
              style={{ backgroundImage: `url(${props.wishlist.image})` }}
              ref={featItemRef}
            ></div>
            <div className="feat-overlay" ref={featOverlayRef}>
              <h2>
                <Link href={`/wunschzettel/${props.wishlist.uuid}`}>
                  <a>
                    {props.wishlist.title}
                    {props.includeUser &&
                      props.wishlist.owner &&
                      ` (von ${props.wishlist.owner.username})`}
                  </a>
                </Link>
              </h2>
              <p
                onClick={() => {
                  setCollapse(!collapse);
                }}
                dangerouslySetInnerHTML={{
                  __html: withMarkdownLinks(props.wishlist.description),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
