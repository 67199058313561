import { WishProcessingStatus } from "@bitte-kaufen/bitte-kaufen-common/dist/@types/wish";
import {
  faExclamationCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.less";

export function ProcessingStatus({ wish }) {
  if (wish.processingStatus === WishProcessingStatus.IN_PROGRESS) {
    return (
      <div className="processing-status">
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    );
  }

  if (wish.processingStatus === WishProcessingStatus.FAILED) {
    return (
      <div className="processing-status failed">
        <FontAwesomeIcon icon={faExclamationCircle} />
      </div>
    );
  }

  return <></>;
}
