import fetch from "isomorphic-unfetch";
import endpointConfig from "../config/endpoints";
import { sanitizeWish } from "@bitte-kaufen/bitte-kaufen-common/dist/src/models/wish";
import { decorateWish } from "@bitte-kaufen/bitte-kaufen-common/dist/src/decorators/wish-decorator";

export const BOUGHT = "bought";
export const OPEN = "open";

export function groupByState(wishes) {
  function getKey(wish) {
    const decoratedWish = decorateWish(wish);

    if (decoratedWish.completed) return BOUGHT;
    return OPEN;
  }

  return wishes.reduce(
    (acc, wish) => {
      const key = getKey(wish);

      return {
        ...acc,
        [key]: [...acc[key], wish],
      };
    },
    { [OPEN]: [], [BOUGHT]: [] }
  );
}

export async function createWish(wishlistId, cookie, data) {
  const config = endpointConfig();

  return await fetch(`${config.wishes(wishlistId)}?auto=true&mode=async`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Cookie: cookie,
    },
    method: "POST",
    body: JSON.stringify(data),
  }).then(
    (response) => response.ok && response.json().then(sanitizeWish),
    () => null
  );
}

export function updateWish(wishlistUuid, wishUuid, data) {
  const config = endpointConfig();

  return fetch(config.wish(wishlistUuid, wishUuid), {
    method: "PATCH",
    body: JSON.stringify(data),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.ok && response.json().then(sanitizeWish));
}

export function deleteWish(wishlistUuid, wishUuid) {
  const config = endpointConfig();

  return fetch(config.wish(wishlistUuid, wishUuid), {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function getWishes(wishlistUuid, options = {}) {
  const queryParamsList = [];

  if (options.filterWishes) {
    queryParamsList.push(`filterWishes=${options.filterWishes}`);
  }
  if (options.wishlistIdentifierField) {
    queryParamsList.push(`identifierField=${options.wishlistIdentifierField}`);
  }

  const queryParams =
    queryParamsList.length === 0 ? "" : `?${queryParamsList.join("&")}`;
  const requestUrl = endpointConfig().wishlist(wishlistUuid) + queryParams;

  return fetch(requestUrl, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(
      (res) => res.ok && res.json(),
      () => {
        /* todo handle this case */
      }
    )
    .then((x) => x.wishes.map(sanitizeWish))
    .then(sortWishes);
}

export function sortWishes(wishes) {
  return sortBy(wishes, "position");
}

export const sortBy = (array, prop) => {
  const getValue = (obj) => {
    const value = obj[prop];

    return typeof value === "number" ? value : value?.toUpperCase();
  };

  return array.sort(function (a, b) {
    const valueA = getValue(a);
    const valueB = getValue(b);

    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  });
};
