import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import GiftFinderSaveDialogBody from "../GiftFinderSaveDialogBody";

const MySwal = withReactContent(Swal);

export default async function triggerGiftFinderSaveDialog({
  wishlists,
  onConfirm,
  onSelect,
  onDone,
  i18n,
}) {
  await MySwal.fire({
    customClass: { container: "giftfinder-save-dialog" },
    title: i18n.t("giftFinder.copyWishTitle"),
    confirmButtonText: i18n.t("common.save"),
    cancelButtonText: i18n.t("common.cancel"),
    showCancelButton: true,
    showLoaderOnConfirm: true,
    preConfirm: onConfirm,
    html: (
      <GiftFinderSaveDialogBody
        i18n={i18n}
        wishlists={wishlists}
        onSelect={onSelect}
      />
    ),
  }).then(
    (result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: i18n.t("giftFinder.successfullyAdded"),
          icon: "success",
          timer: 3000,
          timerProgressBar: true,
        });

        party.confetti(document.querySelector(".swal2-popup"));
      }
    },
    (e) => {
      console.error(e);
      MySwal.fire({
        title: i18n.t("giftFinder.addingFailed"),
        icon: "error",
        timer: 3000,
        timerProgressBar: true,
      });
    }
  );

  onDone();
}
