import { decorateWish } from "@bitte-kaufen/bitte-kaufen-common/dist/src/decorators/wish-decorator";
import classNames from "classnames";
import PubSub from "pubsub-js";
import { updateWish } from "../../models/wish";
import WishEditor from "./components/WishEditor";
import { getFallbackImage } from "./components/WishImage";
import WishRenderer from "./components/WishRenderer";
import "./style.less";

export default class Wish extends React.Component {
  constructor(props) {
    super(props);

    this.fallbackImage = getFallbackImage(props.createdAt);
    this.state = { ...props };

    PubSub.subscribe("page.refresh", (_msg, { user }) => {
      this.setState({ user });
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  async persistWishUpdate(e, transientWish) {
    e.preventDefault();

    const allowedProps = [
      "title",
      "description",
      "url",
      "image",
      "cents",
      "currency",
      "quantity",
    ];
    const delta = Object.fromEntries(
      allowedProps.map((prop) => [prop, transientWish[prop]])
    );

    await updateWish(this.state.wishlist.uuid, this.state.uuid, delta);

    this.setState({
      ...delta,
      transientWish: undefined,
    });
    this.toggleEditMode();
  }

  async updateWish(change) {
    this.setState(change);
  }

  toggleEditMode() {
    this.setState({
      editMode: !this.state.editMode,
      transientWish: Object.assign({}, this.state.transientWish || this.state),
    });
  }

  render() {
    const decoratedWish = decorateWish(this.state);

    return (
      <article className="list-item post type-post status-publish format-standard has-post-thumbnail hentry category-lifestyle wish">
        <div
          className={classNames("wrapper", {
            bought: !this.state.forOwner && decoratedWish.completed,
            reserved: !this.state.forOwner && decoratedWish.completed,
          })}
        >
          {this.state.editMode ? (
            <WishEditor
              {...this.state}
              image={this.state.image || this.fallbackImage}
              persistWishUpdate={(e, wish) => this.persistWishUpdate(e, wish)}
              onEditToggle={() => {
                this.toggleEditMode();
              }}
            />
          ) : (
            <WishRenderer
              giftfinder={this.props.giftfinder}
              {...this.state}
              image={this.state.image || this.fallbackImage}
              onChange={(data) => this.updateWish(data)}
              onEditToggle={() => {
                this.toggleEditMode();
              }}
            />
          )}
        </div>
      </article>
    );
  }
}
