import { faCheckCircle, faCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import "./style.less";

export default function GiftFinderSaveDialogBody({
  i18n,
  wishlists,
  onSelect,
}) {
  const [selectedWishlist, setSelectedWishlist] = useState(
    wishlists && wishlists[0]
  );

  return (
    <div>
      <div className="description">
        {i18n.t("giftFinder.selectWishlistDescription")}
      </div>
      <ul>
        {wishlists.map((wishlist) => (
          <li key={wishlist.uuid}>
            <a
              onClick={() => {
                setSelectedWishlist(wishlist);
                onSelect(wishlist);
              }}
            >
              <FontAwesomeIcon
                icon={wishlist === selectedWishlist ? faCheckCircle : faCircle}
                className="icon"
              />
              {wishlist.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
