import Logo from "../Logo";
import LatestPosts from "./LatestPosts";
import AboutUs from "./AboutUs";
import "./style.less";

export default (props) => (
  <aside id="sidebar">
    {props.children}
    {/* 
      temporarily disabled until migration to sendinblue is finished
      {!props.withoutNewsletter && <Newsletter />} 
    */}
    {props.withLatestPosts && <LatestPosts />}
    {!props.withoutAboutUs && <AboutUs />}
    {props.withLogo && (
      <div className="mobile-only sidebar-logo">
        <Logo />
      </div>
    )}
  </aside>
);
