import { decorateWish } from "@bitte-kaufen/bitte-kaufen-common/dist/src/decorators/wish-decorator";
import i18n from "../../../../models/i18n";

export function getWishDescription(wish) {
  const { processing } = decorateWish(wish);

  if (processing.isPending || processing.hasFailed) {
    return i18n.t(`wish.processing.${wish.processingStatus}`);
  }

  return wish.description;
}

export function hasRelevantPriceReduction(wish, minReductionPercentage = 10) {
  const reduction = !!wish.cents && !!wish.currentCents && Math.round((1 - wish.currentCents / wish.cents) * 100);

  return !!reduction && reduction >= minReductionPercentage;
}
