import { decorateUser } from "@bitte-kaufen/bitte-kaufen-common/dist/src/decorators/user-decorator";
import {
  faBookmark,
  faList,
  faPiggyBank,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Router from "next/router";
import PubSub from "pubsub-js";
import { useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useI18n } from "../../../../../hooks/use-i18n";
import { createUser } from "../../../../../models/user";

import "./style.less";

const ReactSwal = withReactContent(Swal);

function register(data) {
  return createUser({ ...data, flow: "purchase" }).then((res) => {
    if (res.error) {
      return { error: res.error };
    }

    PubSub.publish("page.refresh", { user: res });
    PubSub.publish("header.refresh");

    return res;
  });
}

export function PromptLoginModal({ owner, onDismiss, onComplete }) {
  let decoratedOwner;

  const i18n = useI18n();

  if (owner) {
    decoratedOwner = decorateUser(owner);
  }

  const inputLabel = (() => {
    if (decoratedOwner) {
      return i18n.t("wishlist.reservePromptLogin.whoAreYou.teaser", {
        username: decoratedOwner.displayName,
      });
    } else {
      return i18n.t("wishlist.reservePromptLogin.whoAreYou.teaserAnonymous");
    }
  })();

  useEffect(() => {
    ReactSwal.fire({
      title: i18n.t("wishlist.reservePromptLogin.whoAreYou.title"),
      input: "text",
      inputLabel,
      allowEscapeKey: false,
      inputPlaceholder: i18n.t("register.form.placeholders.yourName"),
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: i18n.t("common.save"),
      denyButtonText: i18n.t("wishlist.reservePromptLogin.whoAreYou.deny"),
      cancelButtonText: i18n.t(
        "wishlist.reservePromptLogin.iAlreadyHaveAnAccount"
      ),
      allowOutsideClick: false,
      customClass: {
        container: "login-prompt",
        htmlContainer: "swal-small-text",
      },
      didRender() {
        ReactSwal.getContainer().setAttribute("data-testid", "swal-container");
      },
      preConfirm(value) {
        if (!value) {
          return Swal.showValidationMessage(
            i18n.t("wishlist.reservePromptLogin.validations.enterUsername")
          );
        }

        return value;
      },
    })
      .then(async (result) => {
        if (result.isDismissed) {
          if (result.dismiss === "backdrop") {
            // User clicked on the background
            onDismiss();
          } else if (result.dismiss === "cancel") {
            // User wants to login with an existing account
            const redirectUrl = encodeURIComponent(window.location.href);
            Router.push(`/konto/einloggen?ru=${redirectUrl}`);
          }
        } else if (result.isConfirmed) {
          // User wants to save the username
          return { firstName: result.value };
        } else if (result.isDenied) {
          // User doesn't want to enter a username
          onComplete({ user: null });
        }
      })
      .then((data) => {
        const { firstName } = data || {};

        if (!firstName) {
          return;
        }

        return ReactSwal.fire({
          title: i18n.t(
            "wishlist.reservePromptLogin.completeYourAccount.title",
            { username: firstName }
          ),
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: i18n.t("register.form.register"),
          allowEscapeKey: false,
          allowOutsideClick: false,
          html: (
            <>
              <p>
                {i18n.t(
                  "wishlist.reservePromptLogin.completeYourAccount.teaser1"
                )}
              </p>
              <p style={{ marginTop: 10, fontWeight: "bold" }}>
                {i18n.t(
                  "wishlist.reservePromptLogin.completeYourAccount.teaser2"
                )}
              </p>
              <ul>
                <li>
                  <FontAwesomeIcon
                    icon={faBookmark}
                    style={{ marginRight: 10 }}
                  />
                  {i18n.t(
                    "wishlist.reservePromptLogin.completeYourAccount.benefits.1"
                  )}
                </li>
                <li>
                  <FontAwesomeIcon icon={faList} style={{ marginRight: 10 }} />
                  {i18n.t(
                    "wishlist.reservePromptLogin.completeYourAccount.benefits.2"
                  )}
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faPiggyBank}
                    style={{ marginRight: 10 }}
                  />

                  {i18n.t(
                    "wishlist.reservePromptLogin.completeYourAccount.benefits.3"
                  )}
                </li>
              </ul>
              <div className="input-group">
                <label
                  htmlFor="swal2-input-email"
                  className="swal2-input-label"
                >
                  {i18n.t("register.form.placeholders.email")}
                </label>
                <input
                  className="swal2-input"
                  id="swal2-input-email"
                  placeholder={`Deine E-Mail-Adresse`}
                  type="email"
                  required
                ></input>
              </div>
              <div className="input-group">
                <label
                  htmlFor="swal2-input-password"
                  className="swal2-input-label"
                >
                  {i18n.t("register.form.placeholders.password")}
                </label>
                <input
                  className="swal2-input"
                  id="swal2-input-password"
                  placeholder="Wähle ein Passwort"
                  type="password"
                  required
                ></input>
              </div>
            </>
          ),
          didRender() {
            ReactSwal.getContainer().setAttribute(
              "data-testid",
              "swal-container"
            );
          },
          preConfirm: async () => {
            const email = document.getElementById("swal2-input-email").value;
            const password = document.getElementById(
              "swal2-input-password"
            ).value;

            if (!email) {
              Swal.showValidationMessage(
                i18n.t("wishlist.reservePromptLogin.validations.enterEmail")
              );
            } else if (!password) {
              Swal.showValidationMessage(
                i18n.t("wishlist.reservePromptLogin.validations.enterPassword")
              );
            } else {
              return register({ firstName, email, password }).then((user) => {
                if (user.error) {
                  Swal.showValidationMessage(
                    i18n.t(`register.messages.registrationFailed.${user.error}`)
                  );
                } else {
                  onComplete({ user });
                }
              });
            }
          },
          preDeny: async () => {
            // User doesn't want to enter an email address

            return register({ firstName }).then((user) => {
              onComplete({ user });
            });
          },
          denyButtonText: i18n.t(
            "wishlist.reservePromptLogin.completeYourAccount.nameSuffices"
          ),
          cancelButtonText: i18n.t(
            "wishlist.reservePromptLogin.iAlreadyHaveAnAccount"
          ),
          customClass: {
            container: "login-prompt",
            htmlContainer: "swal-small-text",
          },
          showLoaderOnConfirm: true,
          showLoaderOnDeny: true,
        }).then(async (result) => {
          if (result.isDismissed) {
            if (result.dismiss === "backdrop") {
              // User clicked on the background
              onDismiss();
            } else if (result.dismiss === "cancel") {
              // User wants to login with an existing account
              const redirectUrl = encodeURIComponent(window.location.href);
              Router.push(`/konto/einloggen?ru=${redirectUrl}`);
            }
          }
        });
      });
  }, []);

  return <></>;
}
