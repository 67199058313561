import { formatDate } from "../../helpers/date";
import { getLatestPosts } from "../../models/blog";
import i18n from "../../models/i18n";

function getThumbnail(post) {
  const media = post._embedded["wp:featuredmedia"];

  return (
    media && media["0"] && media["0"].media_details.sizes.thumbnail.source_url
  );
}

export default class LatestPosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: []
    };
  }

  async componentDidMount() {
    const posts = await getLatestPosts();

    this.setState({ posts });
  }

  render() {
    return <div className="widget laurel_latest_news_widget">
      <h4 className="widget-title">{i18n.t('web.sidebar.blogPosts.title')}</h4>

      {this.state.posts &&
        this.state.posts.map(post => (
          <div key={post.id} className="side-pop list">
            <div className="side-pop-img">
              <a href={post.link} rel="bookmark">
                <img
                  width="520"
                  height="400"
                  src={getThumbnail(post)}
                  className="attachment-laurel-misc-thumb size-laurel-misc-thumb wp-post-image"
                  alt=""
                />
              </a>
            </div>

            <div className="side-pop-content">
              <h4>
                <a
                  href={post.link}
                  dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                ></a>
              </h4>
              <span className="date">{formatDate(post.date_gmt)}</span>
            </div>
          </div>
        ))}
    </div>;
  }
}