import {
  faCheckSquare,
  faEdit,
  faTimesCircle,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import { faArrowsAltV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortableHandle } from "react-sortable-hoc";
import { withI18n } from "../../../hooks/use-i18n";
import { deleteWish } from "../../../models/wish";
import { decorateWish } from "@bitte-kaufen/bitte-kaufen-common/dist/src/decorators/wish-decorator";

class WishActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      confirmDeletion: false,
    };
  }

  toggleDelete(e) {
    e.preventDefault();

    this.setState({
      confirmDeletion: !this.state.confirmDeletion,
    });
  }

  toggleEdit(e) {
    e && e.preventDefault();

    this.props.onEditToggle();
  }

  async deleteWish(e) {
    e.preventDefault();

    await deleteWish(this.props.wishlist.uuid, this.props.uuid);
    this.props.onRemove({ uuid: this.props.uuid });
  }

  render() {
    const { i18n } = this.props;
    const DragHandle = SortableHandle(() => (
      <a className="move-wish">
        <FontAwesomeIcon icon={faArrowsAltV} className="icon" />
        <span>{i18n.t("web.wishlistsShow.manage.move")}</span>
      </a>
    ));
    const decoratedWish = decorateWish(this.props);
    const { processing } = decoratedWish;
    const actions = (
      <>
        {this.props.forOwner &&
          !decoratedWish.completed &&
          this.props.withDragHandler && <DragHandle />}
        {!processing.hasFailed && !processing.isPending && (
          <a onClick={(e) => this.toggleEdit(e)} className="edit-wish">
            <FontAwesomeIcon icon={faEdit} className="icon" />
            <span>{i18n.t("common.edit")}</span>
          </a>
        )}
        <a onClick={(e) => this.toggleDelete(e)} className="delete-wish">
          <FontAwesomeIcon icon={faTrashAlt} className="icon" />
          <span>{i18n.t("common.delete")}</span>
        </a>
      </>
    );

    const confirmDeletionContent = (
      <>
        <a onClick={(e) => this.deleteWish(e)} className="confirm-deletion">
          <FontAwesomeIcon icon={faTrashAlt} className="icon" />
          {i18n.t("web.wishlistsShow.edit.confirmWishDeletion")}
        </a>

        <a onClick={(e) => this.toggleDelete(e)} className="cancel-deletion">
          <FontAwesomeIcon icon={faTimesCircle} className="icon" />
          {i18n.t("common.cancel")}
        </a>
      </>
    );

    const confirmUpdateContent = (
      <>
        <a onClick={(e) => this.props.onDone(e)} className="confirm-update">
          <FontAwesomeIcon icon={faCheckSquare} className="icon" />
          {i18n.t("common.save")}
        </a>

        <a onClick={(e) => this.toggleEdit(e)} className="cancel-update">
          <FontAwesomeIcon icon={faTimesCircle} className="icon" />
          {i18n.t("common.cancel")}
        </a>
      </>
    );

    return this.props.forOwner ? (
      <div className="share">
        {this.props.editMode && confirmUpdateContent}
        {this.state.confirmDeletion && confirmDeletionContent}
        {!this.state.confirmDeletion && !this.props.editMode && actions}
      </div>
    ) : (
      <></>
    );
  }
}

export default withI18n(WishActions);
