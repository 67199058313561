import { useI18n } from "../../hooks/use-i18n";

export default () => {
  const i18n = useI18n();

  return (
    <div className="widget laurel_about_widget">
      <h4 className="widget-title">{i18n.t("web.sidebar.aboutUs.title")}</h4>
      <div className="about-widget">
        <div className="about-img">
          <img
            src="https://bitte.kaufen/magazin/wp-content/uploads/2020/08/sarah-sascha-bitte-kaufen-wunschzettel.jpg"
            alt={i18n.t("web.sidebar.aboutUs.title")}
          />
        </div>
        <p>{i18n.t("web.sidebar.aboutUs.text")}</p>
      </div>
    </div>
  );
};
