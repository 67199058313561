import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useI18n } from "../../hooks/use-i18n";
import "./style.less";

export default function AffiliateNote() {
  const i18n = useI18n();
  const icon = renderToStaticMarkup(
    <FontAwesomeIcon icon={faExternalLinkAlt} />
  );

  return (
    <div className="widget laurel_social_widget affiliate-note">
      <div
        className="sidebar-widget"
        dangerouslySetInnerHTML={{
          __html: i18n.t("web.wishlistsShow.affiliateHint", {
            icon,
          }),
        }}
      ></div>
    </div>
  );
}
