import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneWish } from "@bitte-kaufen/bitte-kaufen-common/dist/src/models/wish";
import { useState } from "react";
import { useI18n } from "../../../../hooks/use-i18n";
import { getWishlists, readFromCookies } from "../../../../models/wishlist";
import triggerGiftFinderNoWishlistsYet from "../GiftFinderNoWishlistsYet";
import triggerGiftFinderSaveDialog from "../GiftFinderSaveDialog";
import "./style.less";

export default function GiftFinderSaveButton({ wish }) {
  const i18n = useI18n();
  const [showSpinner, setShowSpinner] = useState(false);
  const onCopyButtonClick = async () => {
    setShowSpinner(true);

    const permanentWishlists = await getWishlists();
    const transientWishlists = await readFromCookies(undefined, {
      enrich: true,
    });
    const wishlists = [...permanentWishlists, ...transientWishlists];

    let selectedWishlist = wishlists[0];

    if (wishlists.length === 0) {
      return triggerGiftFinderNoWishlistsYet(() => setShowSpinner(false));
    }

    triggerGiftFinderSaveDialog({
      wishlists,
      onConfirm: () => cloneWish(null, selectedWishlist.uuid, wish.uuid),
      onDone: () => setShowSpinner(false),
      onSelect: (wishlist) => (selectedWishlist = wishlist),
      i18n,
    });
  };

  return (
    <>
      <a
        className="read-more action giftfinder-save-button"
        onClick={onCopyButtonClick}
      >
        {i18n.t("giftFinder.copyWishButton")}
        {showSpinner && (
          <>
            &nbsp;
            <FontAwesomeIcon icon={faSpinner} className="icon" spin />
          </>
        )}
      </a>
    </>
  );
}
