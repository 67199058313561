import { getMetaInformation } from "@bitte-kaufen/bitte-kaufen-common/dist/src/models/meta";
import React from "react";

let metaPromise = null;

export function useMeta() {
  const [meta, setMeta] = React.useState(null);

  if (!metaPromise) {
    metaPromise = getMetaInformation();
  }

  React.useEffect(() => {
    metaPromise.then(setMeta);
  }, []);

  return meta;
}

export function withMeta(Component) {
  return (props) => {
    const meta = useMeta();

    return <Component {...props} meta={meta} />;
  };
}
